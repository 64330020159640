<template>
  <div class="dialog">
   <el-dialog title="" :visible.sync="show" center :before-close='close'>
     <div class="xieyi" v-html="companyinfo">
     </div>
  <div slot="footer" class="dialog-footer" >
    <el-button @click="close">取 消</el-button>
  </div>
</el-dialog>
  </div>
</template>

<script>
export default {
  name: "Dialog",
 
  props:['show','companyinfo'],
  data() {
    return {
    };
  },
  mounted () {
  },
  methods: {
    close(){
      this.$emit('close',false)
    }
  
  },
};
</script>

<style  lang="less"  scoped>

</style>