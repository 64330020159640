import request from '@/utils/request'
// 登录名验证
export function checkdata(field,params) {
  return request({
    url: 'web/reg/'+ field +'/checkdata.json?ts=' + (new Date).getTime(),
    method: 'post',
    params
  })
}
// 获取验证码
export function sendyzm(params) {
  return request({
    url: 'web/msg/sendyzm.json?type=adduser',
    method: 'post',
    params
  })
}
// 注册
export function save(params) {
  return request({
    url: 'web/reg/save.json',
    method: 'post',
    params
  })
}

