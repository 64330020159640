var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"header btw"},[_vm._m(0),_c('div',{staticClass:"leftText"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("返回首页")])],1)])]),_c('div',{staticClass:"regCon"},[_c('div',{staticClass:"explain"},[_vm._v(" 欢迎各类企业和个体经营者免费注册，客服电话："+_vm._s(_vm.cominfo.tel)+" "),_c('br'),_vm._v(" 注册信息请务必认真填写，避免因注册审核不通过给您带来的不便。 ")]),_vm._m(1),_c('el-form',{ref:"form",attrs:{"label-width":"150px","model":_vm.form},on:{"submit":function($event){return _vm.reg()}}},[_c('el-form-item',{attrs:{"label":"用户类型","prop":"regtype","rules":[
          { required: true, message: '请选择类型', trigger: 'change' } ]}},[_c('el-radio-group',{on:{"change":_vm.getregtype},model:{value:(_vm.form.regtype),callback:function ($$v) {_vm.$set(_vm.form, "regtype", $$v)},expression:"form.regtype"}},[_c('el-radio',{attrs:{"label":"0"},model:{value:(_vm.form.regtype),callback:function ($$v) {_vm.$set(_vm.form, "regtype", $$v)},expression:"form.regtype"}},[_vm._v("单位")]),_c('el-radio',{attrs:{"label":"1"},model:{value:(_vm.form.regtype),callback:function ($$v) {_vm.$set(_vm.form, "regtype", $$v)},expression:"form.regtype"}},[_vm._v("自然人(投标人)")])],1)],1),(_vm.isDept)?[_c('div',{staticClass:"zjtype"},[_c('el-form-item',{attrs:{"label":"代表类型","prop":"Zjtype","rules":[
              { required: true, message: '请选择类型', trigger: 'change' } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.form.Zjtype),callback:function ($$v) {_vm.$set(_vm.form, "Zjtype", $$v)},expression:"form.Zjtype"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),(_vm.form.Zjtype == 97)?_c('el-form-item',{key:"Zyrycode",attrs:{"label":"社会统一信用号","prop":"Zyrycode","rules":[
              { required: true, validator: _vm.validatePass4, trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"请输入有效的18位统一社会信用编码","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.Zyrycode),callback:function ($$v) {_vm.$set(_vm.form, "Zyrycode", $$v)},expression:"form.Zyrycode"}})],1):_vm._e(),(_vm.form.Zjtype == 98)?_c('el-form-item',{key:"Zyrycode",attrs:{"label":"组织机构代码","prop":"Zyrycode","rules":[
              { required: true, validator: _vm.validatePass4, trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"请按正确格式填写组织机构代码! 正确格式 XXXXXXXX-X","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.Zyrycode),callback:function ($$v) {_vm.$set(_vm.form, "Zyrycode", $$v)},expression:"form.Zyrycode"}})],1):_vm._e(),(_vm.form.Zjtype == 96)?_c('el-form-item',{key:"Zyrycode",attrs:{"label":"其他一证一照代码","prop":"Zyrycode","rules":[
              { required: true, validator: _vm.validatePass4, trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"请输入其他一证一照代码","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.Zyrycode),callback:function ($$v) {_vm.$set(_vm.form, "Zyrycode", $$v)},expression:"form.Zyrycode"}})],1):_vm._e()],1),_c('el-form-item',{key:"Zjtypename",attrs:{"label":"单位名称","prop":"Zjtypename","rules":[
            { required: true, message: '请输入完整的公司名称，注意全角半角区分', trigger: 'blur' },
            { validator: _vm.validatePass } ]}},[_c('el-input',{attrs:{"placeholder":"请输入完整的公司名称，注意全角半角区分","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.Zjtypename),callback:function ($$v) {_vm.$set(_vm.form, "Zjtypename", $$v)},expression:"form.Zjtypename"}})],1),_c('el-form-item',{key:"Detail",attrs:{"label":"注册角色","prop":"Detail","rules":[
            { required: true, message: '请选择注册角色', trigger: 'change' } ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择注册角色"},model:{value:(_vm.form.Detail),callback:function ($$v) {_vm.$set(_vm.form, "Detail", $$v)},expression:"form.Detail"}},_vm._l((_vm.rulelist),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]:_vm._e(),_c('el-form-item',{attrs:{"label":"注册人姓名","prop":"truename","rules":[
          { required: true, message: '请输入注册人的真实姓名', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"请输入注册人的真实姓名","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.truename),callback:function ($$v) {_vm.$set(_vm.form, "truename", $$v)},expression:"form.truename"}})],1),_c('el-form-item',{attrs:{"label":"身份证号","min":"18","max":"18","prop":"by1","rules":[
          { required: true, validator: _vm.validateIdCardNo, trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"请输入18位身份证号","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.by1),callback:function ($$v) {_vm.$set(_vm.form, "by1", $$v)},expression:"form.by1"}})],1),_c('el-form-item',{attrs:{"label":"登录名","prop":"Extloginname","min":"5","max":"20","rules":[
          { required: true, validator: _vm.validatePass, trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"输入登录名请牢记","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.Extloginname),callback:function ($$v) {_vm.$set(_vm.form, "Extloginname", $$v)},expression:"form.Extloginname"}})],1),_c('el-form-item',{attrs:{"label":"登录密码","prop":"extpwd","min":"6","max":"20","rules":[
          { required: true, validator: _vm.validatePass1, trigger: 'blur' } ]}},[_c('el-input',{attrs:{"type":"password","placeholder":"输入不少于6位数字和字母组合","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.extpwd),callback:function ($$v) {_vm.$set(_vm.form, "extpwd", $$v)},expression:"form.extpwd"}})],1),_c('el-form-item',{attrs:{"label":"确认密码","prop":"qrpwd","min":"6","max":"20","rules":[
          { required: true, validator: _vm.validatePass2, trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"输入与登录密码一样","type":"password","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.qrpwd),callback:function ($$v) {_vm.$set(_vm.form, "qrpwd", $$v)},expression:"form.qrpwd"}})],1),(_vm.isDept)?[_c('el-form-item',{key:"deptEmail",attrs:{"label":"邮箱","prop":"ExtEmail","email":"","rules":[
            { required: true, message: '请输入邮箱', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"请输入申报人或公司的常用联系邮箱","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.ExtEmail),callback:function ($$v) {_vm.$set(_vm.form, "ExtEmail", $$v)},expression:"form.ExtEmail"}})],1)]:[_c('el-form-item',{key:"userEmail",attrs:{"label":"邮箱","prop":"Extemail","email":"","rules":[
            { required: true, message: '请输入邮箱', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"请输入申报人或公司的常用联系邮箱","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.Extemail),callback:function ($$v) {_vm.$set(_vm.form, "Extemail", $$v)},expression:"form.Extemail"}})],1)],_c('el-form-item',{attrs:{"label":"验证码","prop":"captcha","rules":[
          { required: true, message: '请输入验证码', trigger: 'blur' } ]}},[_c('el-input',{staticStyle:{"float":"left","width":"220px"},attrs:{"placeholder":"请输入验证码","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.captcha),callback:function ($$v) {_vm.$set(_vm.form, "captcha", $$v)},expression:"form.captcha"}}),_c('img',{staticStyle:{"cursor":"hand","float":"left","margin-left":"10px","height":"35px","margin-top":"2px"},attrs:{"id":"captcha","onclick":"this.src=this.src+'?'+Math.random()","src":_vm.com.getcaptcha}})],1),_c('el-form-item',{attrs:{"label":"手机号","prop":"Extmobile","rules":[{ required: true, validator: _vm.mobile, trigger: 'blur' }]}},[_c('el-input',{attrs:{"placeholder":"请填入收到的手机验证码","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.Extmobile),callback:function ($$v) {_vm.$set(_vm.form, "Extmobile", $$v)},expression:"form.Extmobile"}})],1),_c('el-form-item',{attrs:{"label":"手机验证码","prop":"sjyzm","rules":[
          { required: true, message: '请输入手机验证码', trigger: 'blur' } ]}},[_c('el-input',{staticStyle:{"float":"left","width":"220px"},attrs:{"placeholder":"请输入手机验证码","onkeydown":"if(event.keyCode==32) return false"},model:{value:(_vm.form.sjyzm),callback:function ($$v) {_vm.$set(_vm.form, "sjyzm", $$v)},expression:"form.sjyzm"}}),_c('el-button',{attrs:{"type":"primary","disabled":!_vm.form.Extmobile || !_vm.form.captcha || _vm.isDisable,"loading":_vm.yzm_loading_controller,"icon":"el-icon-warning"},on:{"click":function($event){return _vm.getYzm(1)}}},[_vm._v(_vm._s(_vm.yzm_text))]),_c('div',{staticStyle:{"clear":"both"}})],1),_c('div',{staticStyle:{"padding-left":"150px"}},[_c('el-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}),_vm._v(" 我已接受并同意网站"),_c('a',{staticClass:"xieyi",on:{"click":function($event){return _vm.dialog('注册协议', 'xieyi.html')}}},[_vm._v("《用户服务条款》")])],1),_c('div',{staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("注册用户")])],1)],2)],1),(_vm.xieyishow)?_c('xie-yi',{attrs:{"show":_vm.xieyishow,"companyinfo":_vm.companyinfo},on:{"close":_vm.close}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rightText row"},[_c('img',{staticClass:"logo",attrs:{"src":"/logo.png","alt":""}}),_vm._v(" 欢迎注册易招云采电子招投标交易平台！ ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("单位信息")])])}]

export { render, staticRenderFns }