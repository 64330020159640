<template>
  <div class="register">
    <div class="nav">
      <div class="header btw">
        <div class="rightText row">
          <img class="logo" src="/logo.png" alt="" />
          欢迎注册易招云采电子招投标交易平台！
        </div>
        <div class="leftText">
          <router-link to="/">返回首页</router-link>
        </div>
      </div>
    </div>
    <div class="regCon">
      <div class="explain">
        欢迎各类企业和个体经营者免费注册，客服电话：{{cominfo.tel}} <br />
        注册信息请务必认真填写，避免因注册审核不通过给您带来的不便。
      </div>
      <div class="title"><span>单位信息</span></div>

      <!-- 基本信息的表单 -->
      <el-form label-width="150px" :model="form" ref="form" @submit="reg()">
        <el-form-item
          label="用户类型"
          prop="regtype"
          :rules="[
            { required: true, message: '请选择类型', trigger: 'change' },
          ]"
        >
          <el-radio-group v-model="form.regtype" @change="getregtype">
            <el-radio label="0" v-model="form.regtype">单位</el-radio>
            <el-radio label="1" v-model="form.regtype">自然人(投标人)</el-radio>
          </el-radio-group>
        </el-form-item>

        <template v-if="isDept">
          <div class="zjtype">
            <el-form-item
              label="代表类型"
              prop="Zjtype"
              :rules="[
                { required: true, message: '请选择类型', trigger: 'change' },
              ]"
            >
              <el-select v-model="form.Zjtype" style="width: 100%">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="社会统一信用号"
              key="Zyrycode"
              prop="Zyrycode"
              :rules="[
                { required: true, validator: validatePass4, trigger: 'blur' },
              ]"
              v-if="form.Zjtype == 97"
            >
              <el-input
              placeholder="请输入有效的18位统一社会信用编码"
                v-model="form.Zyrycode"
                onkeydown="if(event.keyCode==32) return false"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="组织机构代码"
              key="Zyrycode"
              prop="Zyrycode"
              :rules="[
                { required: true, validator: validatePass4, trigger: 'blur' },
              ]"
              v-if="form.Zjtype == 98"
            >
              <el-input
               placeholder="请按正确格式填写组织机构代码! 正确格式 XXXXXXXX-X"
                v-model="form.Zyrycode"
                onkeydown="if(event.keyCode==32) return false"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="其他一证一照代码"
              key="Zyrycode"
              prop="Zyrycode"
              :rules="[
                { required: true, validator: validatePass4, trigger: 'blur' },
              ]"
              v-if="form.Zjtype == 96"
            >
              <el-input
                 placeholder="请输入其他一证一照代码"
                v-model="form.Zyrycode"
                onkeydown="if(event.keyCode==32) return false"
              ></el-input>
            </el-form-item>
          </div>

          <el-form-item
            label="单位名称"
            key="Zjtypename"
            prop="Zjtypename"
            :rules="[
              { required: true, message: '请输入完整的公司名称，注意全角半角区分', trigger: 'blur' },
              { validator: validatePass },
            ]"
          >
            <el-input
             placeholder="请输入完整的公司名称，注意全角半角区分"
              v-model="form.Zjtypename"
              onkeydown="if(event.keyCode==32) return false"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="注册角色"
            key="Detail"
            prop="Detail"
            :rules="[
              { required: true, message: '请选择注册角色', trigger: 'change' },
            ]"
          >
            <el-select v-model="form.Detail" style="width: 100%"  placeholder="请选择注册角色">
              <el-option
                v-for="item in rulelist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item
          label="注册人姓名"
          prop="truename"
          :rules="[
            { required: true, message: '请输入注册人的真实姓名', trigger: 'blur' },
          ]"
        >
          <el-input
           placeholder="请输入注册人的真实姓名"
            v-model="form.truename"
            onkeydown="if(event.keyCode==32) return false"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="身份证号"
          min="18"
          max="18"
          prop="by1"
          :rules="[
            { required: true, validator: validateIdCardNo, trigger: 'blur' },
          ]"
        >
          <el-input
          placeholder="请输入18位身份证号"
            v-model="form.by1"
            onkeydown="if(event.keyCode==32) return false"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="登录名"
          prop="Extloginname"
          min="5"
          max="20"
          :rules="[
            { required: true, validator: validatePass, trigger: 'blur' },
          ]"
        >
          <el-input
            placeholder="输入登录名请牢记"
            v-model="form.Extloginname"
            onkeydown="if(event.keyCode==32) return false"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="登录密码"
          prop="extpwd"
          min="6"
          max="20"
          :rules="[
            { required: true, validator: validatePass1, trigger: 'blur' },
          ]"
        >
          <el-input
            type="password"
            v-model="form.extpwd"
            placeholder="输入不少于6位数字和字母组合"
            onkeydown="if(event.keyCode==32) return false"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="qrpwd"
          min="6"
          max="20"
          :rules="[
            { required: true, validator: validatePass2, trigger: 'blur' },
          ]"
        >
          <el-input
             placeholder="输入与登录密码一样"
            type="password"
            v-model="form.qrpwd"
            onkeydown="if(event.keyCode==32) return false"
          >
          </el-input>
        </el-form-item>

        <template v-if="isDept">
          <el-form-item
            label="邮箱"
            prop="ExtEmail"
            email
            :rules="[
              { required: true, message: '请输入邮箱', trigger: 'blur' },
            ]"
            key="deptEmail"
          >
            <el-input
            placeholder="请输入申报人或公司的常用联系邮箱"
              v-model="form.ExtEmail"
              onkeydown="if(event.keyCode==32) return false"
            ></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item
            label="邮箱"
            prop="Extemail"
            email
            :rules="[
              { required: true, message: '请输入邮箱', trigger: 'blur' },
            ]"
            key="userEmail"
          >
            <el-input
              placeholder="请输入申报人或公司的常用联系邮箱"
              v-model="form.Extemail"
              onkeydown="if(event.keyCode==32) return false"
            ></el-input>
          </el-form-item>
        </template>

        <el-form-item
          label="验证码"
          prop="captcha"
          :rules="[
            { required: true, message: '请输入验证码', trigger: 'blur' },
          ]"
        >
          <el-input
           placeholder="请输入验证码"
            v-model="form.captcha"
            style="float: left; width: 220px"
            onkeydown="if(event.keyCode==32) return false"
          >
          </el-input>
          <img
            id="captcha"
            style="
              cursor: pointer;
              cursor: hand;
              float: left;
              margin-left: 10px;
              height: 35px;
              margin-top: 2px;
            "
            onclick="this.src=this.src+'?'+Math.random()"
            :src="com.getcaptcha"
          />
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="Extmobile"
          :rules="[{ required: true, validator: mobile, trigger: 'blur' }]"
        >
          <el-input
            placeholder="请填入收到的手机验证码"
            v-model="form.Extmobile"
            onkeydown="if(event.keyCode==32) return false"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机验证码"
          prop="sjyzm"
          :rules="[
            { required: true, message: '请输入手机验证码', trigger: 'blur' },
          ]"
        >
          <el-input
           placeholder="请输入手机验证码"
            v-model="form.sjyzm"
            style="float: left; width: 220px"
            onkeydown="if(event.keyCode==32) return false"
          >
          </el-input>
          <el-button
            type="primary"
            :disabled="!form.Extmobile || !form.captcha || isDisable"
            @click="getYzm(1)"
            :loading="yzm_loading_controller"
            icon="el-icon-warning"
            >{{ yzm_text }}</el-button
          >
          <div style="clear: both"></div>
        </el-form-item>
        <div style="padding-left: 150px;">
          <el-checkbox v-model="checked"></el-checkbox>
          我已接受并同意网站<a class="xieyi" @click="dialog('注册协议', 'xieyi.html')"
            >《用户服务条款》</a
          >
        </div>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="onSubmit">注册用户</el-button>
        </div>
      </el-form>
    </div>
    <!-- 协议 -->
    <xie-yi v-if="xieyishow" :show="xieyishow" :companyinfo='companyinfo' @close="close"></xie-yi>
  </div>
</template>

<script>
import { checkdata, sendyzm, save } from "@/api/register.js";
import XieYi from "./com/dialog";
export default {
  name: "Register",
  props:['cominfo'],
  components: { XieYi },
  data() {
    return {
      xurl: "/api/web/reg/captcha.htm",
      form: {
        Zjtype: "97",
        Extmobile: "",
        regtype: "0",
        Zyrycode: "",
      },
      options: [
        {
          value: "97",
          label: "统一社会信用代码",
        },
        {
          value: "98",
          label: "组织机构代码",
        },
        {
          value: "96",
          label: "其他一证一照代码",
        },
      ],
      rulelist: [
        {
          value: "投标人",
          label: "投标人",
        },
        {
          value: "招标代理",
          label: "招标代理",
        },
        {
          value: "招标人",
          label: "招标人",
        },
        // {
        //   value: "土地产权出让人",
        //   label: "土地产权出让人",
        // },
        // {
        //   value: "土地产权出让代理机构",
        //   label: "土地产权出让代理机构",
        // },
        // {
        //   value: "土地产权竞买人",
        //   label: "土地产权竞买人",
        // },
        // {
        //   value: "拍卖机构",
        //   label: "拍卖机构",
        // },
      ],
      yzm_loading_controller: false,
      yzm_text: "获取手机验证码",
      isDisable: false,
      checked: false, //确认已同意协议内容
      indexData: {},
      isDept: true,
      indexJson: sessionStorage.getItem("indexJson"),
      xieyishow: false,
      companyinfo:''
    };
  },
  mounted() {},
  methods: {
    getregtype(val) {
      this.isDept = val == 0 ? true : false;
      console.log(val, "getregtype");
    },

    //获取验证码
    getYzm(i) {
      console.log(i);
      this.isDisable = true;
      let params = {
        phone: this.form.Extmobile,
        captcha: this.form.captcha,
      };
      sendyzm(params).then((data) => {
        console.log(data);
        if (data.code == "error") {
          this.isDisable = false;
        } else {
          //倒计时
          this.yzm_loading_controller = true;
          //倒计时
          var remain = 60;
          this.yzm_text = "请在" + remain + "s后重试";

          var int = setInterval(()=> {
            if (remain != 0) {
              remain--;
              this.yzm_text = "请在" + remain + "s后重试";
            } else {
              clearInterval(int);
              this.yzm_text = "获取手机验证码";
              this.isDisable = false;
              this.yzm_loading_controller = false;
            }
          }, 1000);
        }
      });
    },
    // 协议
    dialog() {
      this.xieyishow = true;
      this.companyinfo = this.cominfo.companyinfo
    },
    close() {
      this.xieyishow = false;
    },
    // 保存
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.checked === false) {
            this.$message.error("请勾选我已接受并同意网站《用户服务条款》");
          } else {
            save(this.form).then((data) => {
              if (data.code == "v") {
                this.$alert(data.msg, "提示", {
                  callback:()=>{
                    if(this.form.regtype==1){
                          let url = this.com.loginurl[2].url
                          // window.open(url,'_blank')
                          window.location.href = url
                    }else{
                       let url =''
                       if(this.form.Detail=='投标人'){
                           url = this.com.loginurl[2].url
                       }
                       if(this.form.Detail=='招标代理'){
                           url = this.com.loginurl[6].url
                       }
                         if(this.form.Detail=='招标人'){
                           url = this.com.loginurl[7].url
                       }
                        window.location.href = url

                    }
                    // if (this.indexJson.bangdinglc) {
                    //   window.location.href =
                    //     this.indexJson.website + "/resweb/bdcode.html";
                    // } else {
                    //   window.location.href = this.indexJson.website;
                    // }
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 身份证号
    validateIdCardNo(rule, value, callback) {
      console.log(rule);
      let mailbox = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!mailbox.test(value)) {
        return callback(new Error("请输入正确格式的身份证号！"));
      } else {
        callback();
      }
    },
    //验证输入信息的唯一性  rule是 type   value是data   callback是错误提示等于res.msg
    validatePass(rule, value, callback) {
      let params = {
        type: rule.field,
        txt: value,
      };
      checkdata(rule.field, params).then((data) => {
        if (data.code == "v") {
          callback();
        } else if (data.code == "error") {
          callback(new Error(data.msg));
        }
      });
    },
    //验证组织机构代码  和唯一性
    validatePass4(rule, value, callback) {
      console.log(rule, value);

      if (this.form.Zjtype == "98") {
        var regCode = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{1}$/;
        if (!regCode.test(this.form.Zyrycode)) {
          callback(
            new Error("请按正确格式填写组织机构代码! 正确格式 XXXXXXXX-X")
          );
        } else {
          let params = {
            type: rule.field,
            txt: value,
          };
          checkdata(rule.field, params).then((data) => {
            if (data.code == "v") {
              callback();
            } else if (data.code == "error") {
              callback(new Error(data.msg));
            }
          });
        }
      } else if (this.form.Zjtype == "97") {
        var code = this.form.Zyrycode;
        //18位校验及大写校验
        if (code.length != 18) {
          callback(new Error("不是有效的十八位统一社会信用编码！"));
          this.$message.error("请输入有效的十八位统一社会信用编码！");
        } else {
          let params = {
            type: rule.field,
            txt: value,
          };
          checkdata(rule.field, params).then((data) => {
            if (data.code == "v") {
              callback();
            } else if (data.code == "error") {
              this.$message.error("社会统一代码不能为空");
              callback(new Error(data.msg));
            }
          });
        }
      } else if (this.form.Zjtype == "96") {
        callback();
      }
    },
    //验证输入密码是否按照规则
    validatePass1(rule, value, callback) {
      var reg = /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]+$/;
      // var input = /[^u4e00-u9fa5w]/g;
      if (value === "") {
        callback(new Error("请输入原密码"));
      } else if (value.length < 6 || !reg.test(value)) {
        callback(new Error("输入不少于6位数字和字母组合"));
      } else if (value.length > 20) {
        callback(new Error("密码长度不能超过20位！"));
      } else {
        callback();
      }
    },
    //验证两次密码输入是否一致
    validatePass2(rule, value, callback) {
      if (value == this.form.extpwd) {
        callback();
      } else {
        callback(new Error("两次输入密码不一致"));
      }
    },
    //验证手机号
    mobile(rule, value, callback) {
      var sjh = /^0?(13|14|15|16|18|17|19)[0-9]{9}$/;
      if (!sjh.test(this.form.Extmobile)) {
        callback(new Error("请输入正确的手机号"));
        this.isDisable = true;
      } else {
        let params = {
          type: rule.field,
          txt: value,
        };
        checkdata(rule.field, params).then((data) => {
          if (data.code == "v") {
            callback();
            this.isDisable = false;
          } else if (data.code == "error") {
            callback(new Error(data.msg));
            this.isDisable = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  .nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background: @head-bg;
    .header {
      color: #fff;
      line-height: 40px;
      font-size: 14px;
      width: 800px;
      margin: 0 auto;
      .logo {
        width: 150px;
      }
      .setMrgin {
        padding: 0 10px;
      }
      a{
        color: #fff;
        text-decoration: none;
        &:hover{
          color: @bule;
        }
      }
    }
  }

  .regCon {
    padding: 40px 0 50px;
    width: 800px;
    margin: 0 auto;
  }
  .explain {
    padding: 13px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid @reg-left;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: left;
    font-size: 14px;
    color: #31708f;
  }
  .title {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px dashed #d9e0e7;
    text-align: left;
    span {
      border-left: 4px solid @reg-left;
      padding-left: 15px;
      color:  @bule;
    }
  }
  .xieyi{
    color: @bule;
    cursor: pointer;
  }
}
</style>
